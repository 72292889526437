import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { feriadoStore, mainStore } from '@/utils/store-accessor';
import { newFeriado } from '@/interfaces/feriado';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import SaveButton from '@/components/SaveButton.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.localize('pt_BR', {
    messages: {
        required: (field) => `O campo ${field} é obrigatório.`,
        date_format: (field) => `O campo ${field} deve estar no formato válido.`,
        after: (field) => `A ${field} deve ser uma data posterior a 2 anos atrás.`,
    },
});
Validator.localize('pt_BR');
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.entityObject = null;
        this.feriasDataFinal = null;
        this.afterValidDate = null;
    }
    get feriadoTypeOptions() {
        return [
            'Férias',
            'Feriado Nacional',
            'Facultativo',
            'Feriado Municipal',
            'Feriado Estadual',
            'Evento'
        ];
    }
    async mounted() {
        if (this.$router.currentRoute.params.id) {
            // @ts-ignore
            this.entityObject = await feriadoStore.getFeriado(parseInt(this.$router.currentRoute.params.id, 0));
            this.feriasDataFinal = this.entityObject.data_final;
        }
        else {
            this.entityObject = newFeriado();
        }
        const hoje = new Date();
        this.afterValidDate = new Date(hoje);
        this.afterValidDate.setFullYear(hoje.getFullYear() - 2);
    }
    async submit() {
        let isValid = await this.$refs.observer.validate();
        const error_messages = [];
        const d1 = new Date(this.entityObject.data);
        if (this.feriasDataFinal) {
            const d2 = new Date(this.feriasDataFinal);
            // @ts-ignore
            const diferencaMs = Math.abs(d2 - d1);
            const diff_semanas = Math.floor(diferencaMs / (1000 * 60 * 60 * 24 * 7));
            if ((diff_semanas >= 7) || (diff_semanas < 0)) {
                error_messages.push('Férias com 2 ou mais meses não são recomendados!!');
                isValid = false;
            }
            if (d2 < this.afterValidDate) {
                error_messages.push('A Data final deve ser posterior a 2 anos atrás!');
                isValid = false;
            }
        }
        if (d1 < this.afterValidDate) {
            error_messages.push('A Data inicial deve ser posterior a 2 anos atrás!');
            isValid = false;
        }
        if (!isValid) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            if (error_messages) {
                mainStore.addErrorToasted({
                    // @ts-ignore
                    toasted: this.$toasted,
                    errors: error_messages,
                });
            }
            return;
        }
        this.entityObject.data_final = this.feriasDataFinal;
        if (this.id) {
            const result = await feriadoStore.updateFeriado(this.entityObject);
        }
        else {
            const result = await feriadoStore.createFeriado(this.entityObject);
            this.id = result;
            this.entityObject.id = result;
        }
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
        await this.$router.push({ name: 'main-feriados' });
    }
};
Create = __decorate([
    Component({
        components: {
            SaveButton,
            DateFieldComponent,
            ValidationObserver,
            ValidationProvider
        },
    })
], Create);
export default Create;
